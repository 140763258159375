import LayoutA7 from "../../components/layout-a7"
import React from "react"
import  Login from '../../components/login'

export default class A7Login extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA7/profile/enrollInfoList"
    return(
      <LayoutA7 >
        <Login color={color} src={src} />
      </LayoutA7>
    )
  }
}